<template lang='pug'>
  div
    .font-bold.text-xl Управление складом
    p Будьте аккуратны во время редактирования данных, обратной дороги в этом процессе нет
    
    template(v-if='warehouseInfo')
      p.font-bold.mt-4 Общая информация
      .flex.gap-3.mt-2
        div
          .text-sm Страна
          input(v-model='warehouseInfo.country')
        div
          .text-sm Валюта
          input(v-model='warehouseInfo.currency')
        div
          .text-sm Язык
          input(v-model='warehouseInfo.language')
        div
          .text-sm Комиссия
          input(v-model='warehouseInfo.comission')
        div
          .text-sm Класс АПИ
          input(v-model='warehouseInfo.apiClass')


      p.font-bold.mt-2 Адрес      
      .flex.gap-3.mt-2
        div
          .text-sm Страна
          input(v-model='warehouseInfo.address.country')
        div
          .text-sm Адрес №1
          input(v-model='warehouseInfo.address.address1')
        div
          .text-sm Адрес №2
          input(v-model='warehouseInfo.address.address2')
        div
          .text-sm Улица
          input(v-model='warehouseInfo.address.street')
        div
          .text-sm Дом
          input(v-model='warehouseInfo.address.house')
        div
          .text-sm Квартира
          input(v-model='warehouseInfo.address.flat')
        div
          .text-sm Почт. индекс
          input(v-model='warehouseInfo.address.index')

      p.font-bold.mt-2 Персональные данные      
      .flex.gap-3.mt-2
        div
          .text-sm Имя
          input(v-model='warehouseInfo.address.personal.first_name')
        div
          .text-sm Фамилия
          input(v-model='warehouseInfo.address.personal.last_name')
        div
          .text-sm Отчество
          input(v-model='warehouseInfo.address.personal.middle_name')    
      .flex.gap-3
        div
          .text-sm Страна
          input(v-model='warehouseInfo.address.personal.country')
        div
          .text-sm Номер телефона
          input(v-model='warehouseInfo.address.personal.number')

      p.font-bold.mt-2 Паспортные данные      
      .flex.gap-3.mt-2
        div
          .text-sm Серия паспорта
          input(v-model='warehouseInfo.address.passport.serial')
        div
          .text-sm Номер паспорта
          input(v-model='warehouseInfo.address.passport.number')
        div
          .text-sm Дата выдачи
          input(v-model='warehouseInfo.address.passport.date')   
        div
          .text-sm Орган выдачи
          input(v-model='warehouseInfo.address.passport.maintainer')  
        div
          .text-sm ИНН
          input(v-model='warehouseInfo.address.passport.inn') 

      p.font-bold.mt-2 Банковская информация      
      .flex.gap-3.mt-2
        div
          .text-sm ЛБАН
          input(v-model='warehouseInfo.bank.lban')
        div
          .text-sm БИН
          input(v-model='warehouseInfo.bank.bic')
        div
          .text-sm Владелец
          input(v-model='warehouseInfo.bank.name')   
      .flex.gap-3.mt-2
        div
          .text-sm Адрес №1
          input(v-model='warehouseInfo.bank.address1')  
        div
          .text-sm Адрес №2
          input(v-model='warehouseInfo.bank.address2')  
        div
          .text-sm Страна
          input(v-model='warehouseInfo.bank.country')  
        div
          .text-sm Город
          input(v-model='warehouseInfo.bank.city')  
        div
          .text-sm Индекс
          input(v-model='warehouseInfo.bank.index') 

    .mt-2
      p.font-bold.my-2 Список полок 

      template(v-if='shelves')
        p-table(
          :data='shelves'
          :search='true'
          :headers='{ "_id": "Индетификатор", "size": "Размеры",  "useId": "Закреплена за пользователем" }'
          :actions='{ edit: () => {} }'
        )
      template(v-else)
        p.text-red-500 Не удалось загрузить список полок

    .mt-4.flex.justify-end
      button.pl-2(@click='discard()') Отменить 
      button.bg-green-500.text-white(@click='save()') Сохранить
</template>

<script>
import { getWarehouse, getShelves } from '../assets/api.js';

export default {
  mounted() {
    this.loadData();
  },
  data() {
    return {
      warehouseInfo: null,
      shelves: null
    }
  },
  computed: {
    currentWarehouseId() {
      return this.$route.params.warehouseId;
    }
  },
  methods: {
    async loadData() {
      const result = await getWarehouse(this.currentWarehouseId);
      if (!result.success) {
        console.error('Не удалось загрузить данные склада');
        return;
      }

      this.warehouseInfo = result.data;

      const shelves = await getShelves(this.currentWarehouseId);
      if (!shelves.success) {
        console.error('Не удалось загрузить список полок');
        return;
      }

      shelves.data.forEach(value => {
        const { width, height, inLength, maxWeight } = value.limits;

        value.size = `${width}x${height}x${inLength}, ${maxWeight} kg`; 
      });

      this.shelves = shelves.data;
    }
  }
}
</script>

<style>

</style>